import React from "react";
import CircularProgressBar from "../components/CircularProgressBar";

const Whydoyouneed = () => {
  return (
    <div
      style={{
        backgroundImage: 'url("/heroimg5.jpg")',
        backgroundSize: "100% 100%",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        position: "relative",
      }}
    >
      <div className="container py-4">
        <div className="row py-3">
          <div className="col-md-12">
            <h1 className="hero-title text-center">
              Why do you need<span className="blue"> myprega.com?</span>
            </h1>
            <h3 className="hero_subtitle underlineone my-2">
              We asked more then thousand of moms and mom buddies
            </h3>

            <p className="bold-font">
              What problems have you faced during your pregnancy care?
            </p>
          </div>
        </div>

        <div className="row circle-item">
          <div className="col-md-12">
            <h3 className="hero_subtitle text-center">This is what we found</h3>
            <div className="row">
              <div className="col-12 col-md-4 text-center my-1">
                <div className="d-flex flex-column">
                  <div className="item">
                    <CircularProgressBar percentage={96} />
                  </div>
                  <p className="content-paragraph">
                    Current Pregnancy care leaves you disconnected with your
                    doctor{" "}
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-4 text-center my-1">
                <div className="d-flex flex-column">
                  <div className="item">
                    <CircularProgressBar percentage={82} />
                  </div>
                  <p className="content-paragraph">
                    Lack of Transparency and Visibility in care act as silent
                    killer during journey{" "}
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-4 text-center my-1">
                <div className="d-flex flex-column">
                  <div className="item">
                    <CircularProgressBar percentage={71} />
                  </div>
                  <p className="content-paragraph">
                    Pregnancy care can feel disorganized due to the lack of
                    standardization in management, pricing, and overall
                    experience{" "}
                  </p>
                </div>
              </div>

              <div className="col-2"></div>
              <div className="col-12 col-md-4 text-center my-1">
                <div className="d-flex flex-column">
                  <div className="item">
                    <CircularProgressBar percentage={79} />
                  </div>
                  <p className="content-paragraph">
                    Lack of medical education on pregnancy and end-to-end
                    management of pregnancy care{" "}
                  </p>
                </div>
              </div>

              <div className="col-12 col-md-4 text-center my-1">
                <div className="d-flex flex-column">
                  <div className="item">
                    <CircularProgressBar percentage={92} />
                  </div>
                  <p className="content-paragraph">
                    Lack of daily pregnancy health tracking and analysis{" "}
                  </p>
                </div>
              </div>
              <div className="col-2"></div>
            </div>
          </div>
        </div>

        <div className="container py-5">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6">
              <h4 className="mb-5 fw-bolder">
                <span className="underlineone text-secondary hero_subtitle">
                  Then We Asked
                </span>
                <br />
              </h4>
              <h3 className="under-subtitle">
                'Are you{" "}
                <span className="blue">satisfied with the solution,</span> that
                you have in age-old pregnancy care?'
              </h3>

              <span className="per mb-4">93% Said </span>
              <span className="per text-danger">NO!</span>
            </div>
            <div className="col-md-6 ">
              <div className="then-we-asked-image">
                <img src="/sadlady.gif" className="sadlady" alt="nt found" />
              </div>
            </div>
          </div>
        </div>
        <div className="container py-3">
          <div className="row">
            <div className="col-md-12">
              <h4 className="note-banner">
                So, together with hundreds of medical professional across, we
                have developed myprega.com
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whydoyouneed;
