import React from "react";
import { Link } from "react-router-dom";

const Pricingmain = ({
  isAtTop,
  imgrad,
  selectedValue,
  handleChangeGetDetails,
  areas,
  proceedtonext,
  
}) => {
  return (
    <>
      <div className="mt-5">
        <div className="mt-5">
          <div className="container p pt-5">
            <div className="row align-items-center">
              <div className="col-md-6">
                <p className="under-subtitle py-4 bold-font ">
                  Finding the right pregnancy care that is not heavy on your
                  pocket is more like a task. To reduce your burden we help
                  anytime anyday with the best all inclusive pregnancy care at
                  an affordable rate.
                </p>
              </div>
              <div className="col-md-6">
                <img src="/savemoney.png" className="w-100" />
              </div>
            </div>
          </div>
          {/* New time line section */}
          {/* <div> */}
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div>
                  <h2 className="hero-title text-red text-center">
                    Your Baby Deserves the Best, Without Breaking the Bank!
                  </h2>
                </div>
                <div className="timeline">
                  <div className="timeline-line"></div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "-100px",
                    }}
                  >
                    <div className="container01 left-container01">
                      <img src="/timeline.png" alt="Timeline"></img>
                      <div className="text-box">
                        <h2 className="hero_subtitle">
                          The million-dollar question – How are we affordable
                          yet promise the best prenatal care?
                        </h2>
                        <p className="bold-font">
                          We aim to provide technology-driven and standardized
                          pregnancy care solutions to everyone without pursuing
                          huge profits. Yes, there are costs involved, and we
                          also need money to sustain the model, but we believe
                          that if a major technological breakthrough is not
                          shared with everyone, then what’s the use for it?
                        </p>
                        <p className="bold-font">
                          It’s as honest as a company can get.
                        </p>
                        <h3 className="second-title ">
                          Now, let’s talk numbers
                        </h3>
                      </div>
                    </div>

                    <div
                      style={{
                        flex: 1,
                        maxWidth: "50%",
                        height: "auto",
                        backgroundImage: "url('/timeline01.png')",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        aspectRatio: "1 / 1",
                      }}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "-100px",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        maxWidth: "50%",
                        height: "auto",
                        backgroundImage: "url('/timeline02.png')",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        aspectRatio: "1 / 1",
                      }}
                    />
                    <div className="container01 right-container01">
                      <img src="/timeline.png"></img>
                      <div className="text-box">
                        <h2 className="hero_subtitle">
                          We claim to help you save up to ₹100,000 every
                          pregnancy. How?
                        </h2>
                        <p className="bold-font">
                          There’s a set expenditure involved in every step of
                          prenatal care. Doctor’s consultaons, lab tests,
                          sonography, regular visits to the hospital which may
                          be far from your residence, regular check-ups for
                          health care concerns arising due to pregnancy such as
                          Gestaonal Diabetes or Hypertensive Disorder, and not
                          to menon unplanned C-Secons, NICU stays, consultancy,
                          and monitoring charges{" "}
                        </p>
                        <p className="bold-font mt-2 mb-5">
                          {" "}
                          All this not only becomes fairly expensive for parents
                          but overwhelming too.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "-100px",
                    }}
                  >
                    <div className="container01 left-container01">
                      <img src="/timeline.png"></img>
                      <div className="text-box">
                        <h2 className="hero_subtitle">How MyPrega Helps?</h2>
                        <p className="bold-font">
                          You just need to select one of our prenatal packages
                          and you are done. No frequent travel is needed and we
                          even choose the best doctor for you in your locaon for
                          your convenience.{" "}
                        </p>
                        <h3 className="second-title ">There’s more!</h3>
                      </div>
                    </div>
                    <div
                      style={{
                        flex: 1,
                        maxWidth: "50%",
                        height: "auto",
                        backgroundImage: "url('/timeline03.png')",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        aspectRatio: "1 / 1",
                      }}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "-100px",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        maxWidth: "50%",
                        height: "auto",
                        backgroundImage: "url('/timeline04.png')",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        aspectRatio: "1 / 1",
                      }}
                    />
                    <div className="container01 mobile-maging right-container01">
                      <img src="/timeline.png"></img>
                      <div className="text-box ">
                        <h2 className="hero_subtitle">
                          Indirect savings with MyPrega?
                        </h2>
                        <ul className="expensive-ul">
                          <li>
                            <img
                              src="/tick.png"
                              alt="tick"
                              className="tick-expensive"
                              style={{
                                width: "20px",
                                height: "20px",
                              }}
                            />
                            Our pregnancy care model focuses on prevention
                            rather than treatment, so our experts try to reduce
                            the chances of health complications, increasing your
                            chances of having a normal delivery.
                          </li>

                          <li>
                            <img
                              src="/tick.png"
                              alt="tick"
                              className="tick-expensive"
                              style={{
                                width: "20px",
                                height: "20px",
                              }}
                            />
                            You don’t even need to go anywhere for any kind of
                            consultaon, be it diet, yoga, meditaon, Garbha
                            Sanskar, or pregnancy educaon – our experts take
                            care of it all from the comfort of your home.
                          </li>
                          <li>
                            <img
                              src="/tick.png"
                              alt="tick"
                              className="tick-expensive"
                              style={{
                                width: "20px",
                                height: "20px",
                              }}
                            />
                            No mental pressure due to unplanned C-Secon and
                            prolonged NICU stay.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <h2
             className="hero-title text-center"
            >
              Now tell us, have you heard of such an eecve prenatal care model
              before? We hadn’t either! That’s how MyPrega was born.{" "}
            </h2>
          </div>
          {/* </div> */}

          <div className="text-center">
            <h4
              className="mb-2 fw-bold text-center text-secondary mobunderline"
              style={{ fontSize: "22px" }}
            >
              Tell us about your Area
            </h4>
          </div>
          <br></br>
          <div className="text-center dropdowns">
            <form className="my-3">
              <select
                name="subject"
                id="subject"
                className="custom-select"
                value={selectedValue}
                onChange={handleChangeGetDetails}
              >
                <option value="">Select Area</option>
                {areas?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item?.name}
                  </option>
                ))}
              </select>
            </form>
          </div>
          <div className="mx-3">
            <div className="my-2 buy">
              <Link
                href="#"
                className="mx-auto text-white"
                onClick={() => proceedtonext()}
              >
                PROCEED
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricingmain;
