import React from "react";
import { Link } from "react-router-dom";

const Packages = () => {
  return (
    <div className="bg-light">
      {" "}
      <div className="container py-5">
        <div className="row">
          <div className="col-md-12">
            <h2 className="hero-title text-center">Our Packages</h2>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-borderless table-package">
                <thead>
                  <tr>
                    <th>
                      <div className="package-title-container">
                        <img src="/E1final.png" className="w-100" />
                        <h4> Elemental Care</h4>
                        <span style={{ color: "#a4113a" }}>
                          {" "}
                          Starting from<i className="bi bi-currency-rupee"></i>0
                        </span>
                        <Link
                          to={"/elementalcare"}
                          style={{
                            color: "blue",
                          }}
                        >
                          Explore More
                        </Link>
                        <Link className="buy" to={"/elementalcare"}>
                          EXPLORE
                        </Link>
                      </div>
                    </th>
                    <th>
                      <div className="package-title-container">
                        <img src="cherishfinal.png" className="w-100" />
                        <h4>Cherish Care</h4>
                        <span style={{ color: "#a4113a" }}>
                          {" "}
                          Starting from<i className="bi bi-currency-rupee"></i>
                          69,999
                        </span>
                        <Link
                          to={"/cherishcare"}
                          style={{
                            color: "blue",
                          }}
                        >
                          Explore More
                        </Link>
                        <Link className="buy" to={"/pricing"}>
                          BUY NOW
                        </Link>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="table-item">
                        <img
                          src="/si1babyholdwidhand.png"
                          className="icon"
                          alt="nt found"
                        />
                        <span>Completely free for 7 days</span>
                      </div>
                    </td>
                    <td>
                      <div className="table-item">
                        <img src="/i1.jpg" className="icon" />
                        <span> 10 Expert Consultation sessions</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>-</td>
                    <td>
                      <div className="table-item">
                        <img src="/i2.jpg" className="icon" />
                        <span>
                          {" "}
                          48 Consultation sessions with Doctor <br />
                          Including both in clinic & online
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>-</td>
                    <td>
                      <div className="table-item">
                        <img src="/i3.jpg" className="icon" />
                        <span>
                          {" "}
                          12 types of Pathology Blood
                          <br />
                          and Genetic Tests
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>-</td>
                    <td>
                      <div className="table-item">
                        <img src="/i4circle.jpg" className="icon" />
                        <span> Bacterial Parental Screening Test</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>-</td>
                    <td>
                      <div className="table-item">
                        <img src="/i5babyind.jpg" className="icon" />
                        <span> 9 sessions of ultrasound scan</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="table-item">
                        <img src="/i6babycap.jpg" className="icon" />
                        <span>
                          Foetus Education (Garbh sanskar)
                          <br />
                          care for complete 7 days
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="table-item">
                        <img src="/i6babycap.jpg" className="icon" />
                        <span>
                          Weekly foetus education sessions
                          <br />
                          are known as garbha sanskar
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>-</td>
                    <td>
                      <div className="table-item">
                        <img src="/i7watch.jpg" className="icon" />
                        <span>
                          {" "}
                          Alternative days body checkup <br />
                          via Health tracker
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>-</td>
                    <td>
                      <div className="table-item">
                        <img src="/i824x7.jpg" className="icon" />
                        <span> 24x7 Emergency Care</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>-</td>
                    <td>
                      <div className="table-item">
                        <img src="/i9pregnantlady.jpg" className="icon" />
                        <span> Complete pregnancy care kit</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>-</td>
                    <td>
                      <div className="table-item">
                        <img src="/i10gift.jpg" className="icon" />
                        <span>
                          Monthly premium exclusive <br />
                          giveaway basket
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="table-item">
                        <img src="/i11.jpg" className="icon1" />
                        <span>Complete dashboard view</span>
                      </div>
                    </td>
                    <td>
                      <div className="table-item">
                        <img src="/i11.jpg" className="icon1" />
                        <span>Complete dashboard view</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Packages;
