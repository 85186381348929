import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "./../home/home.css";
import { webUrl, webUrlGarbhsanskar } from "../Utility/config";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import configData from "../../config.json";
import InteraktScript from "../../common/InteraktScript";
function Elementalcare() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [userid, setUserid] = useState();
  const [patientdata, setPatientdata] = useState([]);
  useEffect(() => {
    const userIdValue = localStorage.getItem("userId");
    setUserid(userIdValue);
  }, [userid]);
  const navigate = useNavigate();
  useEffect(() => {
    getsinglepatientdetail(userid);
  });
  const getsinglepatientdetail = async (id) => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/users/get_single_user`,
        {
          userId: id,
        }
      );

      if (response.data.success) {
        console.log("get single user successfully", response.data.user);

        setPatientdata(response.data.user);

        console.log("<><><><>", patientdata);
      } else {
        console.error(
          "Error adding medical registration details:",
          response.data.error
        );
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };
  const openapp = () => {
    if (patientdata.payment_status == true) {
      window.location.href = `${webUrl}`;
    }
    if (patientdata.payment_status == false) {
      window.location.href = `${webUrlGarbhsanskar}`;
    }
  };
  const openalert = () => {
    alert("Please Sign up");
  };
  return (
    <div className="new-home">
      <Header userid={userid} />
      <div className="container-fluid position-relative round-back margin-top py-5">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h1 className="hero-title text-red">
              Elemental Care:Your 7-day's free exploration of new-age pregnancy
              care!
            </h1>
            <h1 className="under-subtitle py-4 text-bold text-gray-600">
              As the name says it all, our Elemental Package grants you the
              privilege to visualize how our digitally organized pregnancy care
              works at no cost.
            </h1>
            <Link to="/pricing" className="explorepkg text-white mt-3">
              Buy Now
            </Link>
          </div>

          <div className="col-md-6 order-mobile">
            <img
              src="/elementalcareimg4.png"
              alt="nt found"
              className="w-100"
            ></img>
          </div>
        </div>
      </div>

      <div className="bg-white package">
        <div className="container py-5">
          <div className="row align-items-center ">
            <div className="col-md-6 order-mobile">
              <img
                src="/NStarterPackage.png"
                className="w-100"
                alt="nt found"
              />
            </div>
            <div className="col-md-6">
              <h2 className="hero-title text-red">package on the house</h2>

              <p className="bold-font">
                We offer you the starter package at zero cost for a complete 7
                days. Subscribe to this one to access a few of the services we
                provide you within this package. Give it a shot and you'll jump
                onto buying one of the paid packages.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-light ">
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2 className="hero-title text-red">
                24 X 7 availability at one click
              </h2>
              <p className="bold-font">
                Feeling nauseous and its 1 AM already? No worries, your doctor
                will see you even at this hour. With our 24x7 availability and
                emergency care option, all you need to do is click the emergency
                care button and see your health expert.
              </p>
            </div>
            <div className="col-md-6 order-mobile">
              <img
                src="/starterPackage2ndfeaturenew11.png"
                className="w-100"
                alt="nt found"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-md-6 order-mobile">
              <div>
                <img src="/s3.png" className="w-100" alt="nt found" />
              </div>
            </div>
            <div className="col-md-6">
              <h2 className="hero-title text-red">
                Sit Back
                <br /> Every Activity Matters
              </h2>

              <p className="bold-font">
                Feeling nauseous and it's 1 AM already? No worries, your doctor
                will see you even at this hour. With our 24x7 availability and
                emergency care option, all you need to do is click the emergency
                care button and see your health expert.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-light">
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2 className="hero-title text-red">
                Fully-Remote
                <br />
                Cutting-Edge Tech System
              </h2>

              <p className="bold-font">
                Our system is fully remote and does everything you need almost
                every day during your journey. From remembering your entire data
                to keeping it all private, our fully automated dashboard is
                built with groundbreaking technology compliance.
              </p>
            </div>
            <div className="col-md-6 order-mobile">
              <img src="/s4.png" className="w-100" />
            </div>
          </div>
        </div>
      </div>

      <div className="text-center my-5">
        {userid === 0 || userid == null ? (
          <Link
            onClick={openalert}
            className="round-btn m-auto "
          >
            EXPLORE
          </Link>
        ) : (
          <button>
            <Link
              onClick={openapp}
              className="round-btn  m-auto "
            >
              EXPLORE
            </Link>
          </button>
        )}
      </div>
      <Footer />
      <InteraktScript />
    </div>
  );
}

export default Elementalcare;
