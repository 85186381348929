import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "./../home/home.css";
import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import InteraktScript from "../../common/InteraktScript";
function Cherishcare() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [userid, setUserid] = useState(0);
  useEffect(() => {
    const userIdValue = localStorage.getItem("userId");
    setUserid(userIdValue);
  }, [userid]);
  const navigate = useNavigate();
  return (
    <div className="new-home">
      <Header userid={userid} />

      <div className="banner-container position-relative round-back margin-top  ">
        <div className="container-fluid   py-5">
          <div className="row align-items-center ">
            <div className="col-md-6">
              <h1 className="hero-title text-red">
                Cherish Care: The only care your pregnancy will ever need!
              </h1>
              <p className="under-subtitle py-4 text-gray-600">
                From essential health checkups to gift hampers, we get you
                everything covered in one place with our very all-embracing
                Cherish Package. Delivers unbeatable care at an unbeatable price
                through an unbeatable pregnancy platform. This is the package
                your nine-month pregnancy will only need
              </p>
              <Link to="/pricing" className="round-btn btn-icon">
                Buy Now
              </Link>
            </div>
            <div className="col-md-6 order-mobile">
              <img src="/elementalcareimg3.png" alt="nt found"></img>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-light py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 order-mobile">
              <img src="/e1.gif" className="w-100" alt="nt found" />
            </div>
            <div className="col-md-6 ">
              <h2 className="hero-title text-red">
                24x7 Availability At One Click
              </h2>

              <p className="bold-font">
                Getting too nauseous at midnight? Will your doctor see you this
                time? Not a problem, with our 24x7 availability and emergency
                care option, we cater to your need of the hour at a single
                click.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2 className="hero-title text-red">
                With Our Health Experts, Know More
              </h2>
              <p className="bold-font">
                Wondering how many medical experts work on you and your baby's
                growth? We incorporate 10 medical experts accompanied by 48
                consultations under one roof.
              </p>
            </div>
            <div className="col-md-6 order-mobile">
              <img src="/e2.gif" className="w-100" alt="nt found" />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-light">
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-md-6 order-mobile">
              <img src="/e3.gif" className="w-100" alt="nt found" />
            </div>
            <div className="col-md-6">
              <h2 className="hero-title text-red">
                Sit Back, Every Activity Matters
              </h2>

              <p className="bold-font">
                Sit Back, Every Activity Matters. During your crucial journey,
                every little activity you do affects the baby growing inside
                you. With our Garbh Sanskar (foetus education) program, dive
                deep into its details.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="container  py-5">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2 className="hero-title text-red">Feel a Connection</h2>

              <p className="bold-font">
                With a single motto within tranarency between you and your
                doctor, we help you build your trust and feel a connection with
                your experts.
              </p>
            </div>
            <div className="col-md-6 order-mobile">
              <img src="/e4.gif" className="w-100" />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-light">
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-md-6 order-mobile">
              <img src="/e5.gif" className="w-100" alt="nt found" />
            </div>
            <div className="col-md-6">
              <h2 className="hero-title text-red">
                Relax, We Know Your Schedule
              </h2>

              <p className="bold-font">
                Leave the hassle of remembering the essential dates of
                consultancies, tests, scans, visits and more behind. Our fully
                automated remote dashboard remembers it all. Say goodbye to the
                hard copies of prescriptions and other essential documents.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2 className="hero-title text-red">
                In-Clinic Visits, Surprisingly Inevitable
              </h2>

              <p className="bold-font">
                We understand the online consultations help you in every best
                possible way but with those bad contractions and nausea, its
                time for you to visit the clinic.
              </p>
            </div>
            <div className="col-md-6 order-mobile">
              <img src="/e6.gif" className="w-100" />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-light">
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-md-6 order-mobile">
              <img src="/e7.gif" className="w-100" />
            </div>
            <div className="col-md-6">
              <h2 className="hero-title text-red">See Your Baby's Moves</h2>

              <p className="bold-font">
                Since we know how precious this time is for you, we've planned 9
                ultrasound and 12 types of pathology tests for you in a way that
                includes extras like not so conventional tests.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white ">
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2 className="hero-title text-red">
                Be Calm, Health Tracker Does It All
              </h2>

              <p className="bold-font">
                Our Health Tracker where oxy pulse meter reading, temperature
                check, weight and other essential checkups are included already.
                It is designed in a way that it records, measures and updates
                the experts about your baby and your health in a single click.
                The experts remember your health on every visit.
              </p>
            </div>
            <div className="col-md-6 order-mobile">
              <img src="/e8.gif" className="w-100" />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-light ">
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-md-6 order-mobile">
              <img src="/e9.gif" className="w-100" />
            </div>
            <div className="col-md-6">
              <h2 className="hero-title text-red">
                We Keep Your Data Under Wraps
              </h2>

              <p className="bold-font">
                We know why privacy matters, so thats why your entire data
                including health records, prescriptions,reports, chats and
                schedule is end-to-end encrypted and lies only between you and
                your medical experts. No one including us, can have access to
                them other than you and your experts.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2 className="hero-title text-red">
                Prescriptions & Reports In Apple-Pie Order
              </h2>

              <p className="bold-font">
                You get lifetime access to your account on our automated
                platform. It keeps you away from the fuss of managing the
                reports, prescriptions, chats and other data as the platform
                does it for you!
              </p>
            </div>
            <div className="col-md-6 order-mobile">
              <img src="/e10.gif" className="w-100" />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-light">
        <div className="container py-5">
          <div className="row align-items-center ">
            <div className="col-md-6 order-mobile">
              <img src="/e11.gif" class="w-100" />
            </div>
            <div className="col-md-6">
              <h2 className="hero-title text-red">
                A special Hamper To Pamper
              </h2>

              <p className="bold-font">
                Since this is the most precious time of your life we wouldnt
                miss a chance to make you feel ecial. Sending you delightful
                gift hampers every month including ecial fruits, nuts and
                aromatic flowers together is our gesture to let you know youre
                ecial to us.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2 className="hero-title text-red">
                Fully-Remote Yet Cutting-Edge Tech System
              </h2>
              <p className="bold-font">
                Since our system is fully remote, it does everything that you
                need at every step. From remembering all your data to providing
                you with end-to-end encrypted privacy, our system is built with
                groundbreaking technology chunks.
              </p>
            </div>
            <div className="col-md-6 order-mobile">
              <img src="/e12.gif" className="w-100" />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-light">
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-md-6 order-mobile">
              <img src="/e13.gif" className="w-100" />
            </div>
            <div className="col-md-6">
              <h2 className="hero-title text-red">
                Astonishingly Awesome, Price At Rock-Bottom
              </h2>
              <p className="bold-font">
                Make your journey experience memorable at a pocket-friendly
                price.
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="text-center my-5">
        <Link
          to="/pricing"
          className="round-btn mx-auto fw-bolder text-white"
          style={{ fontSize: "20px" }}
        >
          BUY NOW
        </Link>
      </div>
      <br />
      <Footer />
      <InteraktScript />
    </div>
  );
}

export default Cherishcare;
