import React from "react";

const Pricingdetails = ({
  isAtTop,
  pricingparacontent,
  imgrad,
  pricecherishcare,
  headerRef,
  packageDetails,
  subbtn,
  handlePayment,
  sharebtn,
  handleShare,
  democlass,
  month,
  monthno,
  monthhead,
}) => {
  return (
    <>
      <div className="">
        <div className="container p mt-5">
          <div
            id="myHeader"
            style={{
              backgroundColor: isAtTop ? "white" : "white",
              width: "90%",
              margin: "0px auto",
              // position: "sticky",
              // top: "50px",
              // zIndex: "9999",
            }}
          >
            <div
              className="container pt-5 p-0"
              style={{
                boxShadow: "0px 16px 32px 0px rgba(233, 238, 242, 0.4)",
              }}
            >
              <div className="row align-items-center p-3">
                <div className="col-md-5">
                  <img src="/pricingimage.gif" className="w-100" alt="" />
                </div>
                <div className="col-md-7 ">
                  <h1 className="hero-title text-red">Cherish Care</h1>
                  <p
                    className="bold-font"
                  >
                    Finding the right pregnancy care that is not heavy on your
                    pocket is more like a task. To reduce your burden we help
                    anytime anyday with the best all inclusive pregnancy care at
                    an affordable rate.
                  </p>
                </div>
              </div>
              <hr className="w-100 my-3"></hr>
              <div
                className="p-0"
                ref={headerRef}
                style={{
                  position: !isAtTop ? "fixed" : "",
                  top: !isAtTop && "90px",
                  zIndex: "103",

                  width: !isAtTop && "83%",
                  // paddingBottom:!isAtTop && "30px",
                  boxShadow: "0px 16px 32px 0px rgba(233, 238, 242, 0.4)",
                }}
              >
                <div className="container-fluid bg-white">
                  <div className="row py-3 align-items-center">
                    <h1
                      className="py-4 ps-5"
                      style={{
                        display: isAtTop ? "none" : "block",
                        color: "#3C4852",
                        fontWeight: "bold",
                        fontSize: "24px",
                        lineHeight: "120%",
                      }}
                    >
                      Cherish Care
                    </h1>
                    {!isAtTop && (
                      <hr
                        className="mx-auto py-4"
                        style={{ width: "90%" }}
                      ></hr>
                    )}
                    <div className="col-12 col-md-6 text-left fs-1 p-0">
                      <div className="d-flex ps-5 align-items-center">
                        <div>
                          <i class="bi bi-currency-rupee"></i>
                        </div>
                        <div>
                          <h2>{packageDetails?.pricing}</h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 text-left text-md-right">
                      {/* <button className="me-3" >Buy Now</button> */}
                      <button
                        href="#"
                        style={{ fontSize: "23px", ...subbtn }}
                        // style={{subbtn, fontSize: "25px" }}
                        className="me-3 text-white"
                        onClick={handlePayment}
                      >
                        BUY NOW
                      </button>
                      <button style={sharebtn} onClick={handleShare}>
                        <i class="bi bi-share"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <h6 className="hero-title text-red">About</h6>
              <p className="bold-font">
                Complete 10 month pregnancy care package from day 1 of your
                pregnancy till delivery of your baby
              </p>
            </div>
            <div className="col-md-8">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div>
                        <img src="/i1.jpg" className="icon"></img>
                      </div>
                      <div className="ps-3">10 Medical Specialists</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div>
                        <img src="/i2.jpg" className="icon"></img>
                      </div>
                      <div className="ps-3">
                        44 In-Clinic & Online Consultations
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div>
                        <img src="/i3.jpg" className="icon"></img>
                      </div>
                      <div className="ps-3">
                        17 Pathology Blood & Genetics lab test
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div>
                        <img src="/i5babyind.jpg" className="icon"></img>
                      </div>
                      <div className="ps-3">
                        09 Ultrtasound (Sonography) Scans
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div>
                        <img src="/i1.jpg" className="icon"></img>
                      </div>
                      <div className="ps-3">117 Yoga Sessions</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div>
                        <img src="/i1.jpg" className="icon"></img>
                      </div>
                      <div className="ps-3">18 Meditation Sessions</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div>
                        <img src="/i6babycap.jpg" className="icon"></img>
                      </div>
                      <div className="ps-3">
                        Garbhasanskar Acess for 9 Month
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div>
                        <img src="/i7watch.jpg" className="icon"></img>
                      </div>
                      <div className="ps-3">
                        Daily Pregnancy Health Tracking
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div>
                        <img src="/i824x7.jpg" className="icon"></img>
                      </div>
                      <div className="ps-3">24/7 Emergency</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div>
                        <img src="/i1.jpg" className="icon"></img>
                      </div>
                      <div className="ps-3">Education on Pregnancy</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div>
                        <img src="/i9pregnantlady.jpg" className="icon"></img>
                      </div>
                      <div className="ps-3">Pregnancy Care Kit</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div>
                        <img src="/i10gift.jpg" className="icon"></img>
                      </div>
                      <div className="ps-3">
                        Monthly Premium Exclusive Giveaway Basket
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div>
                        <img src="/i11.jpg" className="icon"></img>
                      </div>
                      <div className="ps-3">
                        Complete Transparency Over Care Through Dashboard
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-5">
          <div className="row">
            <div className="col-md-4">
              <h6 className="hero-title text-red">Medical Specialist</h6>
            </div>
            <div className="col-md-8">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div className="ps-3">
                        <div
                          className=" text-secondary"
                          style={{ fontWeight: "bold", color: "black" }}
                        >
                          Gynecologist
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div className="ps-3">
                        <div
                          className=" text-secondary"
                          style={{ fontWeight: "bold", color: "black" }}
                        >
                          General Physician{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div className="ps-3">
                        <div
                          className=" text-secondary"
                          style={{ fontWeight: "bold", color: "black" }}
                        >
                          Paediatrician
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div className="ps-3">
                        <div
                          className=" text-secondary"
                          style={{ fontWeight: "bold", color: "black" }}
                        >
                          Yoga Expert
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div className="ps-3">
                        <div
                          className=" text-secondary"
                          style={{ fontWeight: "bold", color: "black" }}
                        >
                          Dietitian
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div className="ps-3">
                        <div
                          className=" text-secondary"
                          style={{ fontWeight: "bold", color: "black" }}
                        >
                          Psychiatrist
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div className="ps-3">
                        <div
                          className=" text-secondary"
                          style={{ fontWeight: "bold", color: "black" }}
                        >
                          Breastfeeding Consultant
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div className="ps-3">
                        <div
                          className=" text-secondary"
                          style={{ fontWeight: "bold", color: "black" }}
                        >
                          Meditation Expert
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div className="ps-3">
                        <div
                          className=" text-secondary"
                          style={{ fontWeight: "bold", color: "black" }}
                        >
                          Diagnostics Lab Center
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div className="ps-3">
                        <div
                          className=" text-secondary"
                          style={{ fontWeight: "bold", color: "black" }}
                        >
                          Sonography Center (Radiologist)
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container py-5">
          <div className="row">
            <div className="col-md-4">
              <h6 className="hero-title text-red">
                Schedule
                <br /> Daily{" "}
              </h6>
              <p className="font-bold"></p>
            </div>
            <div className=" col-md-8">
              <div className="container">
                <div className="row">
                  <div className="col-12 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div className="d-flex flex-column align-items-center">
                        <div>
                          <h6
                            style={{
                              month,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            Month
                          </h6>
                        </div>
                        <div>
                          <h4
                            style={{
                              monthno,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            1
                          </h4>
                        </div>
                      </div>
                      <div className="ps-5">
                        <div className=" text-secondary">
                          <h3
                            style={{
                              monthhead,
                              fontSize: "16px",
                              fontWeight: "bolder",
                            }}
                          >
                            7 Diagnostic lab tests, 1 Sonography, 1 Gynecologist
                            consultation, 1 General Physician consultation, 1
                            Dietitian consultation, 1 Psychiatrist consultation,
                            13 Yoga sessions, and 2 Meditation sessions.
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div className="d-flex flex-column align-items-center">
                        <div>
                          <h6
                            style={{
                              month,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            Month
                          </h6>
                        </div>
                        <div>
                          <h4
                            style={{
                              monthno,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            2
                          </h4>
                        </div>
                      </div>
                      <div className="ps-5">
                        <div className=" text-secondary">
                          <h3
                            style={{
                              monthhead,
                              fontSize: "16px",
                              fontWeight: "bolder",
                            }}
                          >
                            1 Gynecologist consultation, 1 Psychiatrist
                            consultation, 13 Yoga sessions, and 2 Meditation
                            sessions.
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div className="d-flex flex-column align-items-center">
                        <div>
                          <h6
                            style={{
                              month,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            Month
                          </h6>
                        </div>
                        <div>
                          <h4
                            style={{
                              monthno,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            3
                          </h4>
                        </div>
                      </div>
                      <div className="ps-5">
                        <div className=" text-secondary">
                          <h3
                            style={{
                              monthhead,
                              fontSize: "16px",
                              fontWeight: "bolder",
                            }}
                          >
                            1 Gynecologist consultation, 1 General Physician
                            consultation, 1 Dietitian consultation, 1
                            Psychiatrist consultation, 13 Yoga sessions, and 2
                            Meditation sessions.
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div className="d-flex flex-column align-items-center">
                        <div>
                          <h6
                            style={{
                              month,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            Month
                          </h6>
                        </div>
                        <div>
                          <h4
                            style={{
                              monthno,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            4
                          </h4>
                        </div>
                      </div>
                      <div className="ps-5">
                        <div className=" text-secondary">
                          <h3
                            style={{
                              monthhead,
                              fontSize: "16px",
                              fontWeight: "bolder",
                            }}
                          >
                            5 Diagnostic lab tests, 2 Sonography, 2 Gynecologist
                            consultation, 2 General Physician consultation, 1
                            Psychiatrist consultation, 13 Yoga sessions, and 2
                            Meditation sessions.
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div className="d-flex flex-column align-items-center">
                        <div>
                          <h6
                            style={{
                              month,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            Month
                          </h6>
                        </div>
                        <div>
                          <h4
                            style={{
                              monthno,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            5
                          </h4>
                        </div>
                      </div>
                      <div className="ps-5">
                        <div className=" text-secondary">
                          <h3
                            style={{
                              monthhead,
                              fontSize: "16px",
                              fontWeight: "bolder",
                            }}
                          >
                            1 Sonography, 1 Gynecologist consultation, 1
                            Dietitian consultation, 1 Psychiatrist consultation,
                            13 Yoga sessions, and 2 Meditation sessions.
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div className="d-flex flex-column align-items-center">
                        <div>
                          <h6
                            style={{
                              month,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            Month
                          </h6>
                        </div>
                        <div>
                          <h4
                            style={{
                              monthno,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            6
                          </h4>
                        </div>
                      </div>
                      <div className="ps-5">
                        <div className=" text-secondary">
                          <h3
                            style={{
                              monthhead,
                              fontSize: "16px",
                              fontWeight: "bolder",
                            }}
                          >
                            1 Sonography, 1 Gynecologist consultation, 1
                            Psychiatrist consultation, 13 Yoga sessions, and 2
                            Meditation sessions.
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div className="d-flex flex-column align-items-center">
                        <div>
                          <h6
                            style={{
                              month,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            Month
                          </h6>
                        </div>
                        <div>
                          <h4
                            style={{
                              monthno,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            7
                          </h4>
                        </div>
                      </div>
                      <div className="ps-5">
                        <div className=" text-secondary">
                          <h3
                            style={{
                              monthhead,
                              fontSize: "16px",
                              fontWeight: "bolder",
                            }}
                          >
                            4 Diagnostic lab tests, 1 Sonography, 2 Gynecologist
                            consultation, 2 General Physician consultation, 1
                            Dietitian consultation, 1 Psychiatrist consultation,
                            1 Pediatrician consultation, 13 Yoga sessions, and 2
                            Meditation sessions.
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div className="d-flex flex-column align-items-center">
                        <div>
                          <h6
                            style={{
                              month,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            Month
                          </h6>
                        </div>
                        <div>
                          <h4
                            style={{
                              monthno,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            8
                          </h4>
                        </div>
                      </div>
                      <div className="ps-5">
                        <div className=" text-secondary">
                          <h3
                            style={{
                              monthhead,
                              fontSize: "16px",
                              fontWeight: "bolder",
                            }}
                          >
                            2 Gynecologist consultation, 1 General Physician
                            consultation, 1 Psychiatrist consultation, 13 Yoga
                            sessions, and 2 Meditation sessions.
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center">
                      <div className="d-flex flex-column align-items-center">
                        <div>
                          <h6
                            style={{
                              month,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            Month
                          </h6>
                        </div>
                        <div>
                          <h4
                            style={{
                              monthno,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            9
                          </h4>
                        </div>
                      </div>
                      <div className="ps-5">
                        <div className=" text-secondary">
                          <h3
                            style={{
                              monthhead,
                              fontSize: "16px",
                              fontWeight: "bolder",
                            }}
                          >
                            1 Diagnostic lab tests, 2 Sonography, 3 Gynecologist
                            consultation, 3 General Physician consultation, 1
                            Dietitian consultation, 1 Psychiatrist consultation,
                            1 Pediatrician consultation, 13 Yoga sessions, and 2
                            Meditation sessions.
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-1">
                    <div className="d-flex mx-2 bg-white ps-4 py-4 align-items-center mb-5">
                      <div className="d-flex flex-column align-items-center">
                        <div>
                          <h6
                            style={{
                              month,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            Month
                          </h6>
                        </div>
                        <div>
                          <h4
                            style={{
                              monthno,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            10
                          </h4>
                        </div>
                      </div>
                      <div className="ps-5">
                        <div className=" text-secondary">
                          <h3
                            style={{
                              monthhead,
                              fontSize: "16px",
                              fontWeight: "bolder",
                            }}
                          >
                            1 Sonography, 1 Gynecologist consultation, 1 General
                            Physician consultation
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricingdetails;
