import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Confident = () => {
  return (
    <>
      <div className="bg-light py-5">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-12">
              <h2 className="hero-title text-center">
                {" "}
                What makes us so confident?
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="vertical-trigger col-md-6">
              <div>
                <div>
                  <div className="cards">
                    We are the first in the market to provide a comprehensive
                    9-month prenatal care package for mothers by integrating new
                    technology to create an effective pregnancy management mode.
                  </div>
                  <div className="cards">
                    Our process is not only smooth but the best across the
                    healthcare industry. From day one until the day your baby is
                    born, your health remains our top priority
                  </div>
                  <div className="cards">
                    No location limitations, as we specifically curate
                    parameters and set processes to deliver a standardized
                    experience across locations.
                  </div>
                  <div className="cards">
                    We offer complete transparency in care, <br></br>Affordable
                    and value for money.{" "}
                  </div>
                  <div className="cards">
                    Package-based model to avoid delays and confusion, <br></br>
                    Join anytime during your pregnancy.
                  </div>
                  <div className="cards">
                    Exclusive dashboard access to evaluate your journey every
                    step of the way,<br></br>24X7 emergency care.
                  </div>
                  <div className="cards">
                    We train our doctors to ensure every parent has the same
                    amazing experience.
                  </div>
                </div>

                <h2 className="second-title">
                  Sound too good to be true? That’s exactly what we aim for!
                </h2>
                <p className="bold-font paracard burgandi">
                  Pregnancy is a crucial step in your journey to parenthood, and
                  we want to ensure that every aspect is covered.
                </p>
              </div>
            </div>
            <div className="horizontal-trigger  col-md-6 order-mobile">
              {/* <img src="/heroimg1.png">
                </img> */}

              <img
                src="/section2.gif"
                style={{
                  objectFit: "cover",
                  objectPosition: "50% 50%",
                  width: "100%",
                  position: "sticky",
                  top: "120px",
                  maxWidth: "500px",
                  margin: "auto",
                }}
                fetchpriority="high"
              ></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Confident;
