const metatitle = [
  {
    path: "/",
    title: "Best Maternity & Pregnancy Hospital/Care near you | Myprega",
    description:
      "Looking for the best maternity hospital near you? Myprega: A tech-integrated, innovative, & affordable pregnancy care package with 9 experts for 9 months, daily health tracking, and risk prevention. All-in-one care, right at your fingertips. Explore Now!",
  },
  {
    path: "/about",
    title: "The Future of Maternity/Pregnancy Care | Tech-Driven & Affordable",
    description:
      "Learn how Myprega is transforming maternity/pregnancy care with innovative technology, 9-month expert support, prevention-first focus, and an affordable solution for expectant mothers at their fingertips!",
  },
  {
    path: "/elementalcare",
    title:
      "7-Days Free| Best Maternity Hospital/Care for Normal Delivery | Myprega",
    description:
      "Experience Myprega's 7-day free trial! Modern, safe, and complete pregnancy care. Don’t just take our word for it—try first, trust later! Trusted as the best maternity care for normal delivery in Pune. Join today!",
  },
  {
    path: "/cherishcare",
    title:
      "Comprehensive Maternity/Pregnancy Package | Tech-Driven & Affordable",
    description:
      "Discover our tech-driven, innovative, and best maternity / pregnancy care package: daily pregnancy health tracking, 9 medical experts for each mom, risk prevention, and complete 9-month care at affordable prices.",
  },
  {
    path: "/pricing",
    title:
      "Best Maternity & Pregnancy Hospital/Care near you | Myprega",
    description:
      "Looking for the best maternity hospital near you? Myprega: A tech-integrated, innovative, & affordable pregnancy care package with 9 experts for 9 months, daily health tracking, and risk prevention. All-in-one care, right at your fingertips. Explore Now!",
  },
];

export default metatitle;