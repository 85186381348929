import React from "react";

const InteraktScript = () => {
  const handleClick = () => {
    const message =
      "Hi%20Team%20Myprega%21%20%F0%9F%A4%B0%20I%20%E2%80%99m%20curious%20about%20future%20of%20pregnancy%20care.%20Can%20we%20chat%3F";
    const phoneNumber = "919004414624";
    window.open(
      `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`,
      "_blank"
    );
  };

  return (
    <button onClick={handleClick} style={buttonStyle}>
      <img src="/whatsapp-icon.svg" alt="" />
      CHAT WITH US
    </button>
  );
};

const buttonStyle = {
  background: "linear-gradient(rgb(255, 119, 171) 0%, rgb(255, 0, 134) 100%)",
  color: "white",
  border: "none",
  padding: "10px 20px",
  borderRadius: "5px",
  cursor: "pointer",
  fontSize: "16px",
  borderRadius: "30px",
  position: "fixed",
  bottom: "17%",
  right: "1%",
  display: "flex",
  alignItems: "center",
  gap: "10px",
};

export default InteraktScript;
