import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import configData from "../config.json";
import axios from "axios";
import OTPInput from "../Components/OTPInput";
import AlertShow from "../Components/AlertShow";
let myflag = 0;
function Signup() {
  const navigate = useNavigate();
  const [signupForm, setSignupForm] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    password: "",
    roleId: 2,
    areaId: "",
    medicalExpertId: null,
  });

  const [isEmailTaken, setIsEmailTaken] = useState(false);
  const [isPhoneTaken, setIsPhoneTaken] = useState(false);
  const [redemail, setredemail] = useState(false);
  const [redphone, setRedPhone] = useState(false);
  const [areas, setAreas] = useState();
  const [selectedArea, setSelectedArea] = useState(false);
  const [specialisations, setSpecialisations] = useState();
  const [selectedSpecialisation, setSelectedSpecialisation] = useState(false);
  const [invalidOTP, setInvalidOTP] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [passwordStrength, setPasswordStrength] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [verificationStatus, setVerificationStatus] = useState("");
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [timer, setTimer] = useState(90); // Initial timer value in seconds
  const [verifiedno, setVerified] = useState(false);
  const [resetKey, setResetKey] = useState(0);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("");
  // const [recaptcha, setCaptcha] = useState();
  useEffect(() => {
    let interval;
    if (isTimerActive && timer > 0) {
      interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      setIsTimerActive(false);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isTimerActive, timer]);

  useEffect(() => {
    getAreas();
    getSpecialisations();
  }, []);

  const isMobileNumber = (value) => {
    return /^\d{10}$/.test(value);
  };
  const handleOTPComplete = (otp1) => {
    console.log("OTP Entered:", otp, otp1);
    // Add your logic to handle the completed OTP here
    checkpw(otp1);
  };
  const checkpw = (otpentered) => {
    console.log("{}{}{}{}otp check", otpentered);
    console.log("{}{}{}{}otp check", otp);
    if (otpentered === otp) {
      myflag = 1;
      setTimeout(() => {
        setVerified(true);
      }, 1500);
      setInvalidOTP(false);
    } else {
      myflag = 0;
      setVerified(false);
      setInvalidOTP(true);
      resetOtpFields();
      setTimeout(() => {
        // alert("Mobile number/OTP invalid");
        var msg = "Mobile number/OTP invalid";
        const error_color = "error";
        handleAlertMsg(error_color, msg);
      }, 1500);
    }
  };
  const sendSMS = async (e) => {
    e.preventDefault();
    setIsTimerActive(true);
    const otp = generateOTP();

    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/users/sendOtp`,
        {
          phone: signupForm.phone,
          otp: otp,
        }
      );

      if (response && response.data) {
        if (response.data.success) {
          var msg = "OTP sent successfully";
          const error_color = "success";
          handleAlertMsg(error_color, msg);
          // alert("OTP sent successfully");
        } else if (response.data.error) {
          console.error("Error in sending message:", response.data.error);
        } else {
          console.error("Unexpected response structure:", response.data);
        }
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      console.error("Error fetching details:", error.message);
    }
  };
  const resetOtpFields = () => {
    setOtp(["", "", "", "", "", ""]); // Reset the OTP fields
    setResetKey((prevKey) => prevKey + 1);
  };
  const checkemailphone = (e) => {
    e.preventDefault();
    let flag = 0;

    if (
      !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(signupForm.email)
    ) {
      var msg = "Please enter a valid email address.";
      const error_color = "error";
      handleAlertMsg(error_color, msg);
      // alert("Please enter a valid email address.");
      flag = 1;
    }
    // if (!/^\d{10}$/.test(signupForm.phone)) {
    //   alert("Please enter a valid 10-digit phone number.");
    //   flag = 1;
    // }
    if (signupForm.fname == "") {
      var msg = "Please enter first name.";
      const error_color = "error";
      handleAlertMsg(error_color, msg);
      // alert("Please enter first name");
      flag = 1;
    }
    if (signupForm.lname == "") {
      var msg = "Please enter last name";
      const error_color = "error";
      handleAlertMsg(error_color, msg);
      // alert("Please enter last name");
      flag = 1;
    }
    if (signupForm.areaId == "") {
      var msg = "Please select Area of residence";
      const error_color = "error";
      handleAlertMsg(error_color, msg);
      // alert("Please select Area of residence");
      flag = 1;
    }
    if (signupForm.medicalExpertId == "") {
      var msg = "Please select Specialisation";
      const error_color = "error";
      handleAlertMsg(error_color, msg);
      // alert("Please select Specialisation");
      flag = 1;
    }

    if (password !== confirmPassword) {
      var msg = "Passwords do not match";
      const error_color = "error";
      handleAlertMsg(error_color, msg);
      // alert("Passwords do not match");
      flag = 1;
    }
    // if (myflag == 0) {
    //   alert("Invalid Mobile number");
    //   flag = 1;
    // }

    if (flag == 0) {
      handleSignup(e);
    }
  };

  // const sendOTP = () => {
  //    var apiKey = "NTA0NzU1NGQ1NTUyMzE2MjUyNzU2YzQ0Nzg0NjUyNGE=";
  //   const numbers = "9545242173";
  //   const dynamicMessage = generateOTP();

  //   const url = `https://api.textlocal.in/send/?apiKey=${apiKey}&numbers=${numbers}&message=${encodeURIComponent(
  //     dynamicMessage
  //   )}`;

  //   axios
  //     .get(url)
  //     .then((response) => {
  //       if (response.data.status === "success") {
  //         setVerificationStatus("OTP sent successfully");
  //       } else {
  //         setVerificationStatus(
  //           "Error sending OTP: " + response.data.errors[0].message
  //         );
  //       }
  //     })
  //     .catch((error) => {
  //       setVerificationStatus("Error sending OTP: " + error.message);
  //     });
  // };
  const sendOTP = () => {
    const apiKey = "NTA0NzU1NGQ1NTUyMzE2MjUyNzU2YzQ0Nzg0NjUyNGE=";
    const numbers = "8380863007";
    const message = "This is your custom message"; // Your custom message here

    const url = `https://api.textlocal.in/send/?apiKey=${apiKey}&numbers=${numbers}&message=${encodeURIComponent(
      message
    )}`;

    axios
      .get(url)
      .then((response) => {
        if (response.data.status === "success") {
          setVerificationStatus("SMS sent successfully");
        } else {
          setVerificationStatus(
            "Error sending SMS: " + response.data.errors[0].message
          );
        }
      })
      .catch((error) => {
        setVerificationStatus("Error sending SMS: " + error.message);
      });
  };

  const generateOTP = () => {
    const digits = "0123456789";
    let OTP = "";
    for (let i = 0; i < 6; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }

    setOtp(OTP);
    console.log("MYYYYY oootttppp", OTP);
    setIsTimerActive(true);
    setTimer(60); // Reset timer to initial value when OTP is generated
    return OTP;
  };

  const handleSignup = async (e) => {
    console.log("????", signupForm);
    e.preventDefault();
    if (passwordStrength === 4) {
      try {
        const response = await fetch(
          `${configData.SERVER_API_URL}/users/signUp`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(signupForm),
          }
        );

        if (response.ok) {
          const responseData = await response.json();
          if (responseData.success === true) {
            // setLogin(false);
            var msg = "Doctor sign up successfully...";
            const error_color = "success";
            handleAlertMsg(error_color, msg);
            // alert("Doctor sign up successfully...");
            navigate("/doctor/Login");
          } else {
            alert("You have to register on admin");
          }
          console.log("Doctor signed up successfully:", responseData);
        } else {
          const errorData = await response.json();
          var msg = "You have to Register your account on admin panel";
          const error_color = "error";
          handleAlertMsg(error_color, msg);
          console.error("Error signing up doctor:", errorData);
        }
      } catch (error) {
        console.log("An error occurred:", error);
      }
    } else {
      var msg =
        "Please enter strong password Combination of alphabets,digits,symbols";
      const error_color = "error";
      handleAlertMsg(error_color, msg);
    }
  };
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setSignupForm({ ...signupForm, password: newPassword });
    const containsLetter = /[a-zA-Z]/.test(newPassword);
    const containsNumber = /\d/.test(newPassword);
    const containsSymbol = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(newPassword);
    const isLengthValid = newPassword.length >= 8;
    // Password validation criteria (you can customize this)
    const strength =
      (containsLetter ? 1 : 0) +
      (containsNumber ? 1 : 0) +
      (containsSymbol ? 1 : 0) +
      (isLengthValid ? 1 : 0);
    console.log("strength", strength);
    setPasswordStrength(strength);
    setPasswordError("");
  };
  const handlePasswordChange1 = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
  };
  const getAreas = async () => {
    try {
      const response = await axios.get(
        `${configData.SERVER_API_URL}/users/get_areas_list`,
        {}
      );

      if (response.data.success) {
        // console.log("getAreas-", response.data?.list);
        setAreas(response.data?.list);
      }
    } catch (error) {
      console.error("Error get areas:", error);
    }
  };

  const getSpecialisations = async () => {
    try {
      const response = await axios.get(
        `${configData.SERVER_API_URL}/users/get_medical_experts_list`,
        {}
      );

      if (response.data.success) {
        // console.log("specials-", response.data?.list);
        setSpecialisations(response.data?.list);
      }
    } catch (error) {
      console.error("Error get specials:", error);
    }
  };

  const handleAlertMsg = (error_type, msg) => {
    setShowAlert(true);
    setSeverity(error_type);
    setAlertMsg(msg);
    setTimeout(() => {
      setShowAlert(false);
    }, 2500);
  };

  const handleClose = () => {
    setShowAlert(false);
  };

  return (
    <div
      style={{
        backgroundColor: configData.THEME_COLORS.BACKGROUND,
        width: "100%",
        paddingBottom: "50px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="text-center">
        <div>
          <img
            src={"/logo-removebg.png"}
            style={{
              resizeMode: "contain",
              height: "160px",
              width: "160px",
              marginLeft: "20px",
            }}
            width={200}
          />
        </div>
      </div>
      <div className="">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <form
                className="w-100 bg-white mx-auto"
                style={{
                  borderRadius: "12px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      right: "32%",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={"/left2.png"}
                      style={{
                        resizeMode: "contain",
                        height: "34px",
                        width: "auto",
                      }}
                      onClick={() => navigate("/doctor/Login")}
                    />
                  </div>
                  <h4
                    className="fw-bolder py-4"
                    style={{ marginRight: "40px" }}
                  >
                    Sign Up
                  </h4>
                </div>
                <p className="w-100 mx-auto" style={{ color: "#bac1cb" }}>
                  Welcome to Myprega app. Please enter your details here to
                  create account.
                </p>
                <input
                  required
                  type="text"
                  className="my-3"
                  placeholder="Enter First name"
                  style={{
                    width: "50%",
                    minWidth: "300px",
                    height: "45px",
                    border: "1px solid #F0588B",
                    borderRadius: "12px",
                    padding: 8,
                  }}
                  autoComplete="off"
                  value={signupForm.fname}
                  onInput={async (e) => {
                    const fname = e.target.value;
                    if (/^[A-Za-z]+$/.test(fname) || fname === "") {
                      setSignupForm({ ...signupForm, fname: fname });
                    }
                  }}
                  onKeyPress={(e) => {
                    const charCode = e.which || e.keyCode;
                    // Prevent input if the pressed key is a number
                    if (
                      (charCode < 65 || charCode > 90) &&
                      (charCode < 97 || charCode > 122)
                    ) {
                      e.preventDefault();
                    }
                  }}

                  // value={username}
                  // onChange={(e) => setUsername(e.target.value)}
                />
                <br></br>
                <input
                  required
                  type="text"
                  className="my-3"
                  placeholder="Enter Last name"
                  style={{
                    width: "50%",
                    minWidth: "300px",
                    height: "45px",
                    border: "1px solid #F0588B",
                    borderRadius: "12px",
                    padding: 8,
                  }}
                  autoComplete="off"
                  value={signupForm.lname}
                  onInput={async (e) => {
                    const lname = e.target.value;
                    if (/^[A-Za-z]+$/.test(lname) || lname === "") {
                      setSignupForm({ ...signupForm, lname: lname });
                    } else {
                      var msg = "Please enter only alphabetic characters.";
                      const error_color = "error";
                      handleAlertMsg(error_color, msg);
                      // alert("Please enter only alphabetic characters.");
                    }
                  }}
                  onKeyPress={(e) => {
                    const charCode = e.which || e.keyCode;
                    // Prevent input if the pressed key is a number
                    if (
                      (charCode < 65 || charCode > 90) &&
                      (charCode < 97 || charCode > 122)
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
                <br></br>
                <input
                  required
                  type="text"
                  className={`my-3 ${isEmailTaken ? "email-taken" : ""}`}
                  placeholder="Enter mail-id"
                  style={{
                    width: "50%",
                    minWidth: "300px",
                    height: "45px",
                    border:
                      redemail === true ? "1px solid red" : "1px solid #F0588B",
                    borderRadius: "12px",
                    padding: 8,
                  }}
                  autoComplete="off"
                  value={signupForm.email}
                  onChange={async (e) => {
                    const newEmail = e.target.value;
                    console.log(e.target.value);
                    setSignupForm({ ...signupForm, email: newEmail });

                    // Check if the email is valid using an API call
                    try {
                      const response = await fetch(
                        `${configData.SERVER_API_URL}/users/is_email_valid`,
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({ email: newEmail }),
                        }
                      );

                      if (response.ok) {
                        const responseData = await response.json();
                        console.log("valid", responseData);
                        setIsEmailTaken(!responseData.user);
                        if (responseData.user == false) {
                          var msg = "Email is already taken.";
                          const error_color = "error";
                          handleAlertMsg(error_color, msg);
                          // alert("Email is already taken.");
                          setredemail(true);
                        } else {
                          setredemail(false);
                        }
                      } else {
                      }
                    } catch (error) {
                      console.error("An error occurred:", error);
                    }
                  }}
                  pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                  title="Please enter a valid email address"
                />
                <br></br>
                <input
                  required
                  type="number"
                  className="my-3"
                  // className={`my-3 ${isPhoneTaken ? "phone-taken" : ""}`}
                  placeholder="Enter phone number"
                  style={{
                    width: "50%",
                    minWidth: "300px",
                    height: "45px",
                    border: redphone ? "1px solid red" : "1px solid #F0588B",
                    borderRadius: "12px",
                    padding: 8,
                  }}
                  autoComplete="off"
                  value={signupForm.phone}
                  onChange={async (e) => {
                    const newPhone = e.target.value;
                    console.log(newPhone);
                    setSignupForm({ ...signupForm, phone: newPhone });

                    // Check if the phone number is valid using an API call
                    try {
                      const response = await fetch(
                        `${configData.SERVER_API_URL}/users/is_phone_valid`,
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({ phone: newPhone }),
                        }
                      );

                      if (response.ok) {
                        const responseData = await response.json();
                        console.log("Response Data:", responseData);

                        // Update isPhoneTaken state based on API response
                        setIsPhoneTaken(!responseData.user);

                        if (responseData.user == false) {
                          var msg = "Phone number is already taken.";
                          const error_color = "error";
                          handleAlertMsg(error_color, msg);
                          // alert("Phone number is already taken.");
                          setRedPhone(true);
                        } else {
                          setRedPhone(false);
                        }
                      } else {
                        console.log("Phone number validation failed");
                      }
                    } catch (error) {
                      console.error("An error occurred:", error);
                    }
                  }}
                  title="Please enter a valid phone number"
                />
                <div className="w-100 text-end" style={{ width: "60%" }}>
                  {isMobileNumber(signupForm.phone) &&
                  verifiedno == false &&
                  redphone == false ? (
                    <>
                      <span
                        className=""
                        style={{
                          fontSize: "13px",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          if (signupForm.phone === "") {
                            var msg = "Please enter contact number!";
                            const error_color = "error";
                            handleAlertMsg(error_color, msg);

                            return;
                          }
                          sendSMS(e);
                        }}
                        onMouseOver={(e) => {
                          e.target.style.color = "#ee5e8f"; // Change the color on hover
                        }}
                        onMouseOut={(e) => {
                          e.target.style.color = "black"; // Reset the color when not hovering
                        }}
                      >
                        {timer === 0
                          ? "Resend OTP"
                          : invalidOTP == true
                          ? "Resend OTP"
                          : "Send OTP"}
                      </span>
                      <p className="mt-3" style={{ fontSize: "13px" }}>
                        {isTimerActive == true
                          ? `OTP expires in ${timer} seconds`
                          : isTimerActive == false
                          ? timer == 0
                            ? "OTP expired"
                            : ""
                          : ""}
                      </p>
                    </>
                  ) : verifiedno == true ? (
                    <p className="text-success">Mobile number is verified</p>
                  ) : (
                    ""
                  )}

                  <div
                    style={{
                      marginTop: "20px",
                      marginBottom: "10px",
                      width: "60%",
                      margin: "0px auto",
                    }}
                  >
                    {isMobileNumber(signupForm.phone) &&
                      isTimerActive == true &&
                      verifiedno == false && (
                        <OTPInput
                          onComplete={handleOTPComplete}
                          reset={invalidOTP}
                          resetKey={resetKey}
                        />
                      )}
                  </div>
                </div>
                <br></br>
                <input
                  required
                  type="password"
                  className=""
                  placeholder="Enter Password"
                  style={{
                    width: "50%",
                    minWidth: "300px",
                    height: "45px",
                    borderRadius: "12px",
                    border: "1px solid black",
                    padding: 8,
                  }}
                  autoComplete="new-password"
                  value={signupForm.password}
                  onChange={handlePasswordChange}
                />
                <span
                  style={{
                    width: "50%",
                    fontSize: "11px",
                    color: "#757575",
                  }}
                >
                  Use combination of alphabets,digits and special symbols(min 8)
                </span>
                <br />
                {password && (
                  <div className="password-strength-indicator">
                    <div className="progress" style={{ width: "300px" }}>
                      <div
                        className={`progress-bar progress-bar-striped ${
                          passwordStrength === 4
                            ? "bg-success"
                            : passwordStrength == 2
                            ? "bg-warning"
                            : "bg-danger"
                        }`}
                        role="progressbar"
                        style={{ width: `${(passwordStrength / 4) * 100}%` }}
                        aria-valuenow={passwordStrength}
                        aria-valuemin="0"
                        aria-valuemax="4"
                      >
                        {passwordStrength === 4
                          ? "Strong"
                          : passwordStrength == 2
                          ? "Medium"
                          : "Weak"}
                      </div>
                    </div>
                  </div>
                )}
                {/* Confirm password input */}

                <input
                  required
                  type="password"
                  className="my-4"
                  placeholder="Re-type Password"
                  style={{
                    width: "50%",
                    minWidth: "300px",
                    height: "45px",
                    borderRadius: "12px",
                    border: "1px solid black",
                    padding: 8,
                  }}
                  onChange={handlePasswordChange1}
                />
                <br />

                {
                  <select
                    style={{
                      width: "50%",
                      minWidth: "300px",
                      height: "45px",
                      borderRadius: "12px",
                      border: "1px solid black",
                      padding: 8,
                      color: selectedSpecialisation ? "black" : "gray",
                    }}
                    value={selectedArea}
                    onChange={(e) => {
                      setSelectedArea(e.target.value);
                      setSignupForm({ ...signupForm, areaId: e.target.value });
                    }}
                  >
                    <option value="">Select Area</option>
                    {areas?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                }

                <br />
                <select
                  // selected={
                  //   state.id === profileData.clinics[0].state_id
                  // }
                  style={{
                    marginTop: "10px",
                    width: "50%",
                    minWidth: "300px",
                    borderRadius: "12px",
                    border: "1px solid #F0588B",
                    padding: 8,
                    fontSize: "18px",
                    cursor: "pointer",
                    color: selectedSpecialisation ? "black" : "gray",
                  }}
                  value={selectedSpecialisation}
                  onChange={(e) => {
                    setSelectedSpecialisation(e.target.value);
                    setSignupForm({
                      ...signupForm,
                      medicalExpertId: parseInt(e.target.value),
                    });
                  }}
                >
                  <option value="">Select Specialisation</option>
                  {specialisations?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item?.name}
                    </option>
                  ))}
                </select>

                <br />
                {/* <div>
            <h1>OTP Verification</h1>
            <div>
              <input
                type="tel"
                placeholder="Enter Mobile Number"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
              />
              <button onClick={sendOTP}>Send OTP</button>
            </div>
            <div>
              <input
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOTP(e.target.value)}
              />
             
            </div>
            <div>
              <p>{verificationStatus}</p>
            </div>
          </div> */}
                <div
                  className="d-flex align-items-center justify-content-center w-100 mx-auto"
                  style={{ marginBottom: "30px" }}
                >
                  <div className="text-center">
                    <button
                      className="my-4 mx-auto btncreate"
                      style={{
                        width: "120px",
                        height: "40px",
                        borderRadius: "12px",
                        border: "1px solid black",
                        borderRadius: "12px",
                        border: "none",
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={(e) => {
                        checkemailphone(e);
                      }}
                      // disabled={
                      //   isEmailTaken ||
                      //   isPhoneTaken ||
                      //   redemail ||
                      //   redphone ||
                      //   signupForm.password !== signupForm.confirmPassword // Disable if passwords don't match
                      // }
                    >
                      Create
                    </button>
                    <div className="py-4" style={{ color: "#bac1cb" }}>
                      Already have an account?
                      <span
                        className="ps-2"
                        style={{ color: "#ee5e8f", cursor: "pointer" }}
                        onClick={() => navigate("/doctor/Login")}
                      >
                        Login
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <AlertShow
        alertShowHide={showAlert}
        alertClose={handleClose}
        setalertMsg={alertMsg}
        severity={severity}
      />
    </div>
  );
}

export default Signup;
