import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Serviceprovider = () => {
  return (
    <>
      <div className="bg-white">
        <div className="container py-4 ">
          <div className="row">
            <div className="col-md-12">
              <h2 className="hero-title text-center">
                {" "}
                How are we different from other service providers?
              </h2>
            </div>
          </div>
          <div className="row">
          <div className="horizontal-trigger col-md-6">
              <img
                src="/section3.gif"
                style={{
                  width: "100%",
                  position: "sticky",
                  top: "150px",
                }}
                fetchpriority="high"
              ></img>
            </div>
            <div className="vertical-trigger col-md-6">
              <div>
                <div className="cards">
                  Our medical experts are chosen after a thorough screening
                  process.
                </div>
                <div className="cards">
                  You will receive a detailed description of every penny you
                  spend
                </div>
                <div className="cards">
                  Our focus is to minimize the risks of life-threatening
                  conditions among expectant mothers to reduce the chances of
                  complications during childbirth
                </div>
                <div className="cards">
                  We curate the best care package to suit your needs, not the
                  other way around
                </div>
                <div className="cards">
                  We ensure complete transparency throughout your care
                </div>
                <div className="cards">
                  {" "}
                  Standardized packages across locations, so you receive the
                  best prenatal care regardless of your background or location.
                </div>
                <div className="cards">
                  An affordable price range caters to a wider set of people.
                </div>
                <div className="cards">
                  Daily health tracking with a promise of 24/7 emergency care at
                  no extra cost
                </div>
                <div className="cards">Focus on mental well-being</div>
                <div className="cards">
                  Personalized diet plans and live yoga classes
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default Serviceprovider;
