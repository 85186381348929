import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../../App.css";
import Nav from "react-bootstrap/Nav";
import configData from "../../config.json";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";
import axios from "axios";
import MenuIcon from "@mui/icons-material/Menu";
import { webUrl, webUrlGarbhsanskar } from "../Utility/config";
import metatitle from "../../metatitle";
const userid = localStorage.getItem("userId");

function Header(props) {
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [patientdata, setPatientdata] = useState([]);
  const navigate = useNavigate();
  const userid = localStorage.getItem("userId");
  useEffect(() => {
    getsinglepatientdetail(userid);
  }, [userid]);

  useEffect(() => {
    let path = location?.pathname;
    const matchedRoute = metatitle.find((route) => route.path === path);
    if (matchedRoute) {
      document.title = matchedRoute.title;
      let metaDescription = document.querySelector('meta[name="description"]');
      metaDescription.setAttribute("content", matchedRoute.description);
    } else{
      document.title = "My Prega";
      let metaDescription = document.querySelector('meta[name="description"]');
      metaDescription.setAttribute("content", "My Prega");
    }
  }, [location?.pathname]);
  const getsinglepatientdetail = async (id) => {
    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/users/get_single_user`,
        {
          userId: id,
        }
      );

      if (response.data.success) {
        setPatientdata(response.data.user);
      } else {
        console.error("Error fetching user details:", response.data.error);
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLinkClick = (path) => {
    setIsMobileMenuOpen(false);
    navigate(path);
  };
  const openapp = () => {
    localStorage.setItem(
      "isPaidUser",
      patientdata?.payment_status === true ? true : false
    );
    const element = document.querySelector(".calendly-badge-content");
    if (patientdata?.payment_status && element) {
      element.remove("calendly-badge-content");
    }

    const createdDate = new Date(patientdata?.created_date);
    let casepaper = localStorage.getItem("is_casepaper");
    // const createdDate="10 2024 12:27:53 GMT+0530 (India Standard Time)";
    const currentDate = new Date();
    const differenceInTime = currentDate - createdDate;
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    const isWithinSevenDays = differenceInDays <= 7;
    if (isWithinSevenDays) {
      if (patientdata?.payment_status === true) {
        if (casepaper == 0) {
          window.location.href = `${webUrl}`;
        } else {
          navigate("/patient/dashboard");
        }
      } else {
        window.location.href = `${webUrlGarbhsanskar}`;
      }
    } else {
      navigate("/patient/dashboard");
    }
  };
  return (
    <>
      <div
        className={`header-component navbar fixed-top main1 ${
          isScrolled ? "shadow-visible" : "shadow-hidden"
        }`}
        id="shadownav"
      >
        <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
          <Nav.Link
            className="nav-link navbar-brand ps-2"
            aria-current="page"
            onClick={() => handleLinkClick("/")}
          >
            <img src="/logo.png" className="ps-2" id="mylogo" alt="Logo" />
          </Nav.Link>
        </div>
        <div className="d-none d-lg-block">
          <Nav className="ml-auto" style={{ alignItems: "center" }}>
            <Nav.Item style={{ paddingRight: "25px" }}>
              <Nav.Link
                className="nav-link"
                aria-current="page"
                onClick={() => handleLinkClick("/")}
              >
                Home
              </Nav.Link>
            </Nav.Item>
            {parseInt(userid) === 0 || userid === null || userid === "null" ? (
              <>
                <NavDropdown
                  title="Doctor"
                  id="navbarDropdownDoctor"
                  className="nav-link"
                >
                  <NavDropdown.Item href="/doctor/Signup">
                    Sign Up
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/doctor/Login">
                    Login
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  title="Patient"
                  id="navbarDropdownPatient"
                  className="nav-link"
                >
                  <NavDropdown.Item href="/patient/Signup">
                    Sign Up
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/patient/loginform">
                    Login
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            ) : (
              <Nav.Item>
                <Button variant="primary go-to-dashboard-btn" onClick={openapp}>
                  Go to Dashboard
                </Button>
              </Nav.Item>
            )}
            <Nav.Item>
              <Nav.Link className="nav-link" onClick={() => navigate("/about")}>
                About Us
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className="nav-link"
                onClick={() => navigate("/contact")}
              >
                Contact Us
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="d-lg-none pe-2" onClick={toggleMobileMenu}>
          <MenuIcon />
        </div>
        {isMobileMenuOpen && (
          <div className="mobile-menu">
            <Nav className="ml-auto" style={{ alignItems: "center" }}>
              <Nav.Item style={{ paddingRight: "25px", paddingLeft: "20px" }}>
                <Nav.Link
                  className="nav-link nav-item"
                  onClick={() => handleLinkClick("/")}
                >
                  Home
                </Nav.Link>
              </Nav.Item>
              {parseInt(userid) === 0 ||
              userid === null ||
              userid === "null" ? (
                <>
                  <NavDropdown
                    title="Doctor"
                    id="navbarDropdownDoctor"
                    className="nav-link"
                    style={{ paddingRight: "25px", paddingLeft: "20px" }}
                  >
                    <NavDropdown.Item href="/doctor/Signup">
                      Sign Up
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/doctor/Login">
                      Login
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    title="Patient"
                    id="navbarDropdownPatient"
                    className="nav-link"
                    style={{ paddingRight: "25px", paddingLeft: "20px" }}
                  >
                    <NavDropdown.Item href="/patient/Signup">
                      Sign Up
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/patient/loginform">
                      Login
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : (
                <Nav.Item>
                  <Button
                    variant="primary go-to-dashboard-btn"
                    onClick={openapp}
                  >
                    Go to Dashboard
                  </Button>
                </Nav.Item>
              )}

              <Nav.Item style={{ paddingRight: "25px", paddingLeft: "20px" }}>
                <Nav.Link
                  className="nav-item nav-link"
                  onClick={() => handleLinkClick("/about")}
                >
                  About Us
                </Nav.Link>
              </Nav.Item>
              <Nav.Item style={{ paddingRight: "25px", paddingLeft: "20px" }}>
                <Nav.Link
                  className="nav-item nav-link"
                  onClick={() => handleLinkClick("/contact")}
                >
                  Contact Us
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        )}
      </div>
    </>
  );
}

export default Header;
