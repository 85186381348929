import React from "react";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { TypeAnimation } from "react-type-animation";
import { CiCircleChevRight } from "react-icons/ci";

const Banner = () => {
  return (
    <div className="container-fluid px-5 margin-top banner">
      <div className="row align-items-center">
        <div className="col-md-7">
          <h1 className="hero-title text-red">
            What you do in those nine months will shape your child’s future...
          </h1>

          <h3 className="under-subtitle  text-bold text-gray-600">
            That’s why 9 medical experts will guide and support you every step
            of your pregnancy journey through our innovative
            technology-integrated pregnancy management model.{" "}
            <span className="text-red">WHAT ELSE?</span>
          </h3>
          <h3 className="hero_subtitle py-3">
            Don’t just take our word for it—try first, trust later!
          </h3>

          <Link to="/cherishcare" className="round-btn btn-icon pb-3">
            <span>
              <CiCircleChevRight />
            </span>
            Let’s Care for You Together 
          </Link>
          <div className="paragraphp-motion-container mt-5">
            <div className="content-area">
              <TypeAnimation
                sequence={[
                  "Every day in 2020, almost 800 women died from preventable causes related to pregnancy and childbirth",
                  5000,
                  "Women die as a result of complications during and following pregnancy. Most of these complications develop during pregnancy, and most are preventable or treatable.",
                  5000,
                  "Indias infant mortality rate is 3.5%! (Developed countries have <0.2%), and its due to poor healthcare, both during the pregnancy and during the birth.",
                  5000,
                  "The leading causes of maternal death were obstetric haemorrhage (47%), pregnancy-related infection (12%), and hypertensive disorders of pregnancy (7%).",
                  5000,
                ]}
                repeat={Infinity}
              />
            </div>
            <div className="icon-area">
              <SearchIcon />
            </div>
          </div>
        </div>
        <div className="col-md-5 order-mobile">
          <video autoPlay muted loop className="">
            <source src="/heroimgvideo.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default Banner;
