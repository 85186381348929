import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import configData from "../../config.json";
import moment from "moment";
import axios from "axios";
import "firebase/auth";
// import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import OTPInput from "../Components/OTPInput";
import AlertShow from "../Components/AlertShow";
import { useNavigate } from "react-router-dom/dist";
let myflag = 0;
function Signup() {
  // const history = useHistory();
  const navigate = useNavigate();
  const [signupForm, setSignupForm] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    password: "",
    roleId: 1,
    pregnancyType: "",
    LmpDate: "",
    areaId: "",
    planning_days: "",
    dateOfBirth: "",
    referal_code: "",
  });
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isEmailTaken, setIsEmailTaken] = useState(false);
  const [isPhoneTaken, setIsPhoneTaken] = useState(false);
  const [redemail, setredemail] = useState(false);
  const [redphone, setRedPhone] = useState(false);
  const [isCreateDisabled, setIsCreateDisabled] = useState(true);
  const [singleUser, setSingleUser] = useState(null);
  const [dropdownData, setDropdownData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [showLMPInput, setShowLMPInput] = useState(false);
  const [showPlanningInput, setShowPlanningInput] = useState(false);
  const [lmp, setLMP] = useState("");
  const [dob, setDob] = useState("");
  const [otpVerify, setOTPVerify] = useState(false);
  const [invalidOTP, setInvalidOTP] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isTimerActive, setIsTimerActive] = useState(" ");
  // const [phoneNumber, setPhoneNumber] = useState("+918149213642");
  const [verificationCode, setVerificationCode] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [captchaSuccess, setCaptcha] = useState(false);
  const [Area, setAreas] = useState();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(90); // Initial timer value in seconds
  const [verifiedno, setVerified] = useState(false);
  const [resetKey, setResetKey] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("");
  // const [recaptcha, setCaptcha] = useState();
  useEffect(() => {
    let interval;
    if (isTimerActive && timer > 0) {
      interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      setIsTimerActive(false);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isTimerActive, timer]);
  useEffect(() => {
    setTimeout(() => {
      // displayCaptcha();
    }, 500);
  }, []);
  useEffect(() => {
    getAreas();
  }, []);
  const getAreas = async () => {
    try {
      const response = await axios.get(
        `${configData.SERVER_API_URL}/users/get_areas_list`,
        {}
      );

      if (response.data.success) {
        console.log("getAreas-", response.data.list);
        setAreas(response.data?.list);
      }
    } catch (error) {
      console.error("Error get areas:", error);
    }
  };
  useEffect(() => {
    fetch(`${configData.SERVER_API_URL}/common/get_pregnancy_type`)
      .then((response) => response.json())
      .then((data) => {
        console.log("<<<<<DDDDRRR", data.pregnancy_type);
        // setBloodGroups(data.bloodGroup); // Note the key "bloodGroup" from the API response
        setDropdownData(data.pregnancy_type);
      })
      .catch((error) => {
        console.error("Error fetching:", error);
      });
  }, []);
  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    if (selectedValue === "2") {
      setShowLMPInput(true);
      setShowPlanningInput(false);
    } else if (selectedValue === "1") {
      setShowPlanningInput(true);
      setShowLMPInput(false);
    } else {
      setShowLMPInput(false);
      setShowPlanningInput(false);
    }
    setSignupForm({ ...signupForm, pregnancyType: selectedValue });
  };
  const generateOTP = () => {
    const digits = "0123456789";
    let OTP = "";
    for (let i = 0; i < 6; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }

    setOtp(OTP);
    console.log("MYYYYY oootttppp", OTP);
    setIsTimerActive(true);
    setTimer(60); // Reset timer to initial value when OTP is generated
    return OTP;
  };

  const sendSMS = async (e) => {
    e.preventDefault();
    setIsTimerActive(true);
    const otp = generateOTP();

    try {
      const response = await axios.post(
        `${configData.SERVER_API_URL}/users/sendOtp`,
        {
          phone: signupForm.phone,
          otp: otp,
        }
      );

      if (response && response.data) {
        if (response.data.success) {
          var msg = "Message sent successfully";
          const error_color = "success";
          handleAlertMsg(error_color, msg);
          // alert("Message sent successfully");
        } else if (response.data.error) {
          console.error("Error in sending message:", response.data.error);
        } else {
          console.error("Unexpected response structure:", response.data);
        }
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      console.error("Error fetching details:", error.message);
    }
  };
  const setperioddate = (e) => {
    const perioddate = e.target.value;
    const formattedDate = moment(perioddate).format("YYYY-MM-DD");
    // const periodd = moment(perioddate).format("YYYY-MM-DD");
    setLMP(formattedDate);
    setSignupForm({
      ...signupForm,
      ...(perioddate && { LmpDate: formattedDate }),
    });
  };
  const handleChangeBirthDate = (e) => {
    const dateOfBirth = e.target.value;
    const formattedDate = moment(dateOfBirth).format("YYYY-MM-DD");
    setDob(formattedDate);
    setSignupForm({
      ...signupForm,
      ...(dateOfBirth && { dateOfBirth: formattedDate }),
    });
  };
  const getareaname = (e) => {
    const area = e.target.value;
    setSignupForm({ ...signupForm, areaId: area });
  };
  const checkemailphone = (e) => {
    e.preventDefault();
    let flag = 0;

    if (
      !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(signupForm.email)
    ) {
      var msg = "Please enter a valid email address.";
      const error_color = "error";
      handleAlertMsg(error_color, msg);
      // alert("Please enter a valid email address.");
      flag = 1;
    }
    // if (!/^\d{10}$/.test(signupForm.phone)) {
    //   alert("Please enter a valid 10-digit phone number.");
    //   flag = 1;
    // }
    if (signupForm.fname == "") {
      var msg = "Please enter first name";
      const error_color = "error";
      handleAlertMsg(error_color, msg);
      // alert("Please enter first name");
      flag = 1;
    }
    if (signupForm.lname == "") {
      var msg = "Please enter last name";
      const error_color = "error";
      handleAlertMsg(error_color, msg);
      // alert("Please enter last name");
      flag = 1;
    }
    if (signupForm.pregnancyType == "") {
      var msg = "Please select your pregnancy type";
      const error_color = "error";
      handleAlertMsg(error_color, msg);
      // alert("Please select your pregnancy type");
      flag = 1;
    }
    if (signupForm.areaId == "") {
      var msg = "Please select Area of residence";
      const error_color = "error";
      handleAlertMsg(error_color, msg);
      // alert("Please select Area of residence");
      flag = 1;
    }
    if (password !== confirmPassword) {
      var msg = "Passwords do not match";
      const error_color = "error";
      handleAlertMsg(error_color, msg);
      // alert("Passwords do not match");
      flag = 1;
    }
    if (signupForm.pregnancyType == "1" && signupForm.planning_days == "") {
      var msg = "please Enter Planning days";
      const error_color = "error";
      handleAlertMsg(error_color, msg);
      // alert("please Enter Planning days");
      flag = 1;
    }
    if (signupForm.pregnancyType == "2" && signupForm.LmpDate == "") {
      var msg = "Please Select Date Of Last Menstrual Period";
      const error_color = "error";
      handleAlertMsg(error_color, msg);
      // alert("Please Select Date Of Last Menstrual Period");
      flag = 1;
    }
    if (signupForm.dateOfBirth == "") {
      var msg = "Please Select Date of Birth";
      const error_color = "error";
      handleAlertMsg(error_color, msg);
      // alert("Please Select Date of Birth");
      flag = 1;
    }
    // if (myflag === 0) {
    //   alert("Invalid Mobile number");
    //   flag = 1;
    // }

    if (flag == 0) {
      handleSignup(e);
    }
  };
  const isMobileNumber = (value) => {
    return /^\d{10}$/.test(value);
  };
  const handleSignup = async (e) => {
    console.log("????", signupForm);

    const formWithoutBlankPlanningDays = { ...signupForm };
    if (formWithoutBlankPlanningDays.planning_days === "") {
      delete formWithoutBlankPlanningDays.planning_days;
    }
    if (formWithoutBlankPlanningDays.LmpDate === "") {
      delete formWithoutBlankPlanningDays.LmpDate;
    }
    console.log(">>>", formWithoutBlankPlanningDays);
    e.preventDefault();
    if (passwordStrength === 4) {
      try {
        const response = await fetch(
          `${configData.SERVER_API_URL}/users/signUp`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formWithoutBlankPlanningDays),
          }
        );

        if (response.ok) {
          const responseData = await response.json();
          if (responseData.success === true) {
            var msg = "You have signed up successfully...";
            const error_color = "success";
            handleAlertMsg(error_color, msg);
            // alert("You have signed up successfully...");
            navigate("/patient/loginform");
          }

          // Redirect to the dashboard or another page after signup
        } else {
          const errorData = await response.json();
          console.error("Error signing up doctor:", errorData);
        }
      } catch (error) {
        console.log("An error occurred:", error);
      }
    } else {
      var msg =
        "Please enter strong password Combination of alphabets,digits,symbols";
      const error_color = "error";
      handleAlertMsg(error_color, msg);
      // alert(
      //   "Please enter strong password Combination of alphabets,digits,symbols"
      // );
    }
  };

  const handleAlertMsg = (error_type, msg) => {
    setShowAlert(true);
    setSeverity(error_type);
    setAlertMsg(msg);
    setTimeout(() => {
      setShowAlert(false);
    }, 2500);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleOTPComplete = (otp1) => {
    console.log("OTP Entered:", otp, otp1);
    // Add your logic to handle the completed OTP here
    checkpw(otp1);
  };
  const checkpw = (otpentered) => {
    if (otpentered === otp) {
      myflag = 1;
      setTimeout(() => {
        setVerified(true);
      }, 1500);
      setInvalidOTP(false);
    } else {
      myflag = 0;
      setVerified(false);
      setInvalidOTP(true);
      resetOtpFields();
      setTimeout(() => {
        var msg = "Mobile number/OTP invalid";
        const error_color = "error";
        handleAlertMsg(error_color, msg);
        // alert("Mobile number/OTP invalid");
      }, 1000);
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setSignupForm({ ...signupForm, password: e.target.value });
    const containsLetter = /[a-zA-Z]/.test(newPassword);
    const containsNumber = /\d/.test(newPassword);
    const containsSymbol = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(newPassword);
    const isLengthValid = newPassword.length >= 8;
    // Password validation criteria (you can customize this)
    const strength =
      (containsLetter ? 1 : 0) +
      (containsNumber ? 1 : 0) +
      (containsSymbol ? 1 : 0) +
      (isLengthValid ? 1 : 0);
    console.log("strength", strength);
    setPasswordStrength(strength);
    setPasswordError("");
  };
  const handlePasswordChange1 = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
  };
  const handleVerifyOTP = (code) => {
    // if (confirmationResult) {
    //   confirmationResult
    //     .confirm(code)
    //     .then((result) => {
    //       alert("Valid OTP!");
    //       setOTPVerify(true);
    //       console.log("User signed in:", result.user);
    //     })
    //     .catch((error) => {
    //       alert("Invalid OTP, Please Try Again!");
    //       console.error("Error verifying OTP:", error);
    //     });
    // } else {
    //   console.error("Confirmation result is not defined.");
    // }

    if (confirmationResult) {
      confirmationResult
        .confirm(code)
        .then((result) => {
          console.log("User signed in:", result.user);
          var msg = "OTP Verified";
          const error_color = "success";
          handleAlertMsg(error_color, msg);
          // alert("OTP Verified");
          setOTPVerify(true);
        })
        .catch((error) => {
          if (error.code === "auth/invalid-verification-code") {
            console.error("Invalid OTP code. Please enter a valid code.");
            var msg = "Invalid OTP code. Please enter a valid code.";
            const error_color = "error";
            handleAlertMsg(error_color, msg);
            // alert("Invalid OTP code. Please enter a valid code.");
            setInvalidOTP(true);
            // setOTPVerify(true);
            // Handle the error gracefully, such as displaying an error message to the user.
            setTimeout(() => {
              setInvalidOTP(false);
            }, 1000);
          } else {
            console.error("Error verifying OTP:", error);
            var msg = "Invalid OTP";
            const error_color = "error";
            handleAlertMsg(error_color, msg);
            // alert("Invalid OTP");
            setInvalidOTP(true);

            setTimeout(() => {
              setInvalidOTP(false);
            }, 1000);
            return;
          }
        });
    } else {
      console.error("Confirmation result is not defined.");
    }
  };
  const resetOtpFields = () => {
    setOtp(["", "", "", "", "", ""]); // Reset the OTP fields
    setResetKey((prevKey) => prevKey + 1);
  };
  return (
    <div
      style={{
        backgroundColor: configData.THEME_COLORS.BACKGROUND,
        width: "100%",
        paddingBottom: "50px",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="text-center">
        <div>
          <img
            src={"/logo-removebg.png"}
            style={{
              resizeMode: "contain",
              height: "160px",
              width: "160px",
              marginLeft: "20px",
            }}
            width={200}
          />
        </div>
      </div>
      <div className="">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <form
                className="w-100 bg-white mx-auto"
                style={{
                  borderRadius: "12px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      right: "32%",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={"/left2.png"}
                      style={{
                        resizeMode: "contain",
                        height: "34px",
                        width: "auto",
                      }}
                      alt="nt found"
                      onClick={() => navigate("/patient/loginform")}
                    />
                  </div>
                  <h4
                    className="fw-bolder py-4"
                    style={{ marginRight: "40px" }}
                  >
                    Sign Up
                  </h4>
                </div>
                <p className="w-100 text-center" style={{ color: "#bac1cb" }}>
                  Welcome to Myprega app. Please enter your details here to
                  Create Your Account.
                </p>
                <div
                  style={{
                    textAlign: "left",
                    margin: "0px auto",
                    width: "60%",
                  }}
                >
                  <label>Let's start by getting to know you better</label>
                </div>
                <br></br>
                <div
                  style={{
                    textAlign: "left",
                    margin: "0px auto",
                    width: "60%",
                  }}
                >
                  {" "}
                  <label>What's your Name?</label>
                </div>
                <input
                  required
                  type="text"
                  className="my-3"
                  placeholder="Type your first name"
                  style={{
                    width: "60%",
                    height: "45px",
                    borderRadius: "12px",
                    border: "1px solid black",
                    padding: 10,
                  }}
                  autoComplete="off"
                  onInput={async (e) => {
                    const fname = e.target.value;
                    if (/^[A-Za-z]+$/.test(fname) || fname === "") {
                      setSignupForm({ ...signupForm, fname: fname });
                    }
                  }}
                  onKeyPress={(e) => {
                    const charCode = e.which || e.keyCode;
                    // Prevent input if the pressed key is a number
                    if (
                      (charCode < 65 || charCode > 90) &&
                      (charCode < 97 || charCode > 122)
                    ) {
                      e.preventDefault();
                    }
                  }}

                  // value={username}
                  // onChange={(e) => setUsername(e.target.value)}
                />
                <br></br>
                <input
                  required
                  type="text"
                  // className="my-3"
                  placeholder="Type your last name"
                  style={{
                    width: "60%",
                    height: "45px",
                    borderRadius: "12px",
                    border: "1px solid black",
                    padding: 10,
                  }}
                  autoComplete="off"
                  onInput={async (e) => {
                    const lname = e.target.value;

                    if (/^[A-Za-z]+$/.test(lname) || lname === "") {
                      setSignupForm({ ...signupForm, lname: lname });
                    } else {
                      var msg = "Please enter only alphabetic characters.";
                      const error_color = "error";
                      handleAlertMsg(error_color, msg);
                      // alert("Please enter only alphabetic characters.");
                    }
                  }}
                  onKeyPress={(e) => {
                    const charCode = e.which || e.keyCode;
                    // Prevent input if the pressed key is a number
                    if (
                      (charCode < 65 || charCode > 90) &&
                      (charCode < 97 || charCode > 122)
                    ) {
                      e.preventDefault();
                    }
                  }}
                  // value={username}
                  // onChange={(e) => setUsername(e.target.value)}
                />
                <br></br>
                <div
                  style={{
                    textAlign: "left",
                    margin: "0px auto",
                    width: "60%",
                  }}
                >
                  {" "}
                  <label>We can reply to you at</label>
                </div>
                <input
                  required
                  type="text"
                  className={`my-1 ${isEmailTaken ? "email-taken" : ""}`}
                  placeholder="Type your Email"
                  style={{
                    width: "60%",
                    height: "45px",
                    border:
                      redemail === true ? "1px solid red" : "1px solid green",
                    borderRadius: "12px",
                    padding: 10,
                  }}
                  autoComplete="off"
                  value={signupForm.email}
                  onChange={async (e) => {
                    const newEmail = e.target.value;
                    console.log(e.target.value);
                    setSignupForm({ ...signupForm, email: newEmail });

                    try {
                      const response = await fetch(
                        `${configData.SERVER_API_URL}/users/is_email_valid`,
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({ email: newEmail }),
                        }
                      );

                      if (response.ok) {
                        const responseData = await response.json();
                        console.log("valid", responseData);
                        setIsEmailTaken(!responseData.user);
                        if (responseData.user == false) {
                          var msg = "Email is already taken.";
                          const error_color = "error";
                          handleAlertMsg(error_color, msg);
                          // alert("Email is already taken.");
                          setredemail(true);
                        } else {
                          setredemail(false);
                        }
                        // Email is valid, you can show a success message or do other actions
                      } else {
                        // Email is not valid, you can show an error message or do other actions
                      }
                    } catch (error) {
                      console.error("An error occurred:", error);
                    }
                  }}
                  pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                  title="Please enter a valid email address"
                />
                <br></br>
                <div
                  style={{
                    textAlign: "left",
                    margin: "0px auto",
                    width: "60%",
                  }}
                >
                  <label>We can reply to you at when you're offline</label>
                </div>
                <input
                  required
                  type="number"
                  className="my-1"
                  id="register-mobile-no"
                  // className={`my-3 ${isPhoneTaken ? "phone-taken" : ""}`}
                  placeholder="Type your Contact
                Number"
                  style={{
                    width: "60%",
                    height: "45px",
                    border: redphone ? "1px solid red" : "1px solid green",
                    borderRadius: "12px",
                    padding: 10,
                  }}
                  autoComplete="off"
                  value={signupForm.phone}
                  onChange={async (e) => {
                    const newPhone = e.target.value;
                    console.log(newPhone);
                    setSignupForm({ ...signupForm, phone: newPhone });

                    try {
                      const response = await fetch(
                        `${configData.SERVER_API_URL}/users/is_phone_valid`,
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({ phone: newPhone }),
                        }
                      );

                      if (response.ok) {
                        const responseData = await response.json();
                        console.log("Response Data:", responseData);

                        // Update isPhoneTaken state based on API response
                        setIsPhoneTaken(!responseData.user);

                        if (responseData.user == false) {
                          var msg = "Phone number is already taken.";
                          const error_color = "error";
                          handleAlertMsg(error_color, msg);
                          // alert("Phone number is already taken.");
                          setRedPhone(true);
                        } else {
                          setRedPhone(false);
                        }
                      } else {
                        console.log("Phone number validation failed");
                      }
                    } catch (error) {
                      console.error("An error occurred:", error);
                    }
                  }}
                  title="Please enter a valid phone number"
                />
                <div className="text-end" style={{ width: "60%" }}>
                  {isMobileNumber(signupForm.phone) &&
                  verifiedno == false &&
                  redphone == false ? (
                    <>
                      <span
                        className=""
                        style={{
                          fontSize: "13px",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          if (signupForm.phone === "") {
                            var msg = "Please enter contact number!";
                            const error_color = "error";
                            handleAlertMsg(error_color, msg);
                            // alert("Please enter contact number!");
                            return;
                          }
                          sendSMS(e);
                        }}
                        onMouseOver={(e) => {
                          e.target.style.color = "#ee5e8f"; // Change the color on hover
                        }}
                        onMouseOut={(e) => {
                          e.target.style.color = "black"; // Reset the color when not hovering
                        }}
                      >
                        {timer === 0
                          ? "Resend OTP"
                          : invalidOTP == true
                          ? "Resend OTP"
                          : "Send OTP"}
                      </span>
                      <p className="mt-3" style={{ fontSize: "13px" }}>
                        {isTimerActive == true
                          ? `OTP expires in ${timer} seconds`
                          : isTimerActive == false
                          ? timer == 0
                            ? "OTP expired"
                            : ""
                          : ""}
                      </p>
                    </>
                  ) : verifiedno == true ? (
                    <p className="text-success">Mobile number is verified</p>
                  ) : (
                    ""
                  )}

                  <div style={{ marginTop: "20px", marginBottom: "10px" }}>
                    {isMobileNumber(signupForm.phone) &&
                      isTimerActive == true &&
                      verifiedno == false && (
                        <OTPInput
                          onComplete={handleOTPComplete}
                          reset={invalidOTP}
                          resetKey={resetKey}
                        />
                      )}
                  </div>
                </div>

                <br />
                <>
                  <label
                    style={{
                      textAlign: "left",
                      width: "60%",
                      marginTop: "10px",
                    }}
                  >
                    Date of Birth
                  </label>
                  <div
                    style={{
                      textAlign: "left",
                      margin: "0px auto",
                      width: "60%",
                      marginBottom: "20px",
                    }}
                  >
                    <input
                      style={{
                        height: "45px",
                        width: "100%",
                        borderRadius: "12px",
                        border: "1px solid black",
                        padding: 10,
                      }}
                      type="date"
                      value={dob}
                      onChange={handleChangeBirthDate}
                      dateFormat="yyyy-MM-dd"
                      placeholderText="yyyy-mm-dd"
                      autoComplete="off"
                    />
                  </div>
                </>

                <br></br>
                <div
                  style={{
                    textAlign: "left",
                    margin: "0px auto",
                    width: "60%",
                  }}
                >
                  <label>Are You?</label>
                  <select
                    id="dropdown"
                    className="my-1"
                    style={{
                      height: "45px",
                      width: "100%",
                      borderRadius: "12px",
                      border: "1px solid black",
                      padding: 10,
                      cursor: "pointer",
                    }}
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    {/* onChange={(e) => handleChangebg("bg", e.target.value)} */}
                    <option value="">Select an option</option>
                    {dropdownData.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
                {showLMPInput && (
                  <>
                    <label
                      style={{
                        textAlign: "left",
                        width: "60%",
                        marginTop: "10px",
                      }}
                    >
                      Date of your last menstrual period:
                    </label>
                    <div
                      style={{
                        textAlign: "left",
                        margin: "0px auto",
                        width: "60%",
                        marginBottom: "20px",
                      }}
                    >
                      {/* <DatePicker
                    className="a my-1"
                    dateFormat="yyyy-MM-dd"
                    placeholderText="yyyy-mm-dd"
                    style={{ height: "45px", width: "100%" }}
                    showTimeSelect={false} // Disable time selection
                    type="date"
                    value={lmp}
                    onChange={setperioddate}
                  /> */}
                      <input
                        style={{
                          height: "45px",
                          width: "100%",
                          borderRadius: "12px",
                          border: "1px solid black",
                          padding: 10,
                        }}
                        type="date"
                        value={lmp}
                        onChange={setperioddate}
                        dateFormat="yyyy-MM-dd"
                        placeholderText="yyyy-mm-dd"
                        autoComplete="off"
                        // onChange={(e) => setLMP(e.target.value)}
                      />
                    </div>
                  </>
                )}

                {showPlanningInput && (
                  <>
                    <label
                      style={{
                        textAlign: "left",
                        width: "60%",
                        marginBottom: "10px",
                      }}
                    >
                      After how many days are you planning a pregnancy?
                    </label>
                    <div
                      style={{
                        textAlign: "left",
                        margin: "0px auto",
                        width: "60%",
                        marginBottom: "20px",
                      }}
                    >
                      <input
                        style={{
                          height: "45px",
                          width: "100%",
                          borderRadius: "12px",
                          border: "1px solid black",
                          padding: 10,
                        }}
                        type="number"
                        // value={planningDays}
                        onChange={(e) => {
                          const planningdays = parseInt(e.target.value);
                          console.log("@@@", planningdays);

                          setSignupForm({
                            ...signupForm,
                            ...(planningdays && {
                              planning_days: planningdays,
                            }),
                          });
                        }}
                      />
                    </div>
                  </>
                )}
                <br></br>
                <div
                  style={{
                    textAlign: "left",
                    margin: "0px auto",
                    width: "60%",
                  }}
                >
                  <label>Select Area of residence</label>
                  <select
                    className="my-1"
                    style={{
                      height: "45px",
                      width: "100%",
                      borderRadius: "12px",
                      border: "1px solid black",
                      padding: 10,
                      cursor: "pointer",
                    }}
                    onChange={getareaname}
                  >
                    <option value="">Select Area</option>
                    {Area?.map((i) => (
                      <option key={i.id} value={i.id}>
                        {i?.name}
                      </option>
                    ))}
                  </select>
                </div>
                <input
                  type="text"
                  className="mt-3 mb-2"
                  placeholder="Enter referral code "
                  style={{
                    width: "60%",
                    height: "45px",
                    borderRadius: "12px",
                    border: "1px solid black",
                    padding: 10,
                  }}
                  onChange={(e) => {
                    setSignupForm({
                      ...signupForm,
                      referal_code: e.target.value,
                    });
                  }}
                />
                <input
                  required
                  type="password"
                  className="mt-3 mb-2"
                  placeholder="Enter Password"
                  style={{
                    width: "60%",
                    height: "45px",
                    borderRadius: "12px",
                    border: "1px solid black",
                    padding: 10,
                  }}
                  autoComplete="new-password"
                  onChange={handlePasswordChange}
                />
                <span
                  style={{
                    width: "50%",
                    fontSize: "11px",
                    color: "#757575",
                  }}
                >
                  Use combination of alphabets,digits and special symbols(min 8)
                </span>
                <br />
                {password && (
                  <div className="password-strength-indicator">
                    <div className="progress" style={{ width: "300px" }}>
                      <div
                        className={`progress-bar progress-bar-striped ${
                          passwordStrength === 4
                            ? "bg-success"
                            : passwordStrength == 2
                            ? "bg-warning"
                            : "bg-danger"
                        }`}
                        role="progressbar"
                        style={{ width: `${(passwordStrength / 4) * 100}%` }}
                        aria-valuenow={passwordStrength}
                        aria-valuemin="0"
                        aria-valuemax="4"
                      >
                        {passwordStrength === 4
                          ? "Strong"
                          : passwordStrength == 2
                          ? "Medium"
                          : "Weak"}
                      </div>
                    </div>
                  </div>
                )}
                {/* Confirm password input */}

                <input
                  required
                  type="password"
                  className="mt-2 mb-2"
                  placeholder="Re-type Password"
                  style={{
                    width: "60%",
                    height: "45px",
                    borderRadius: "12px",
                    border: "1px solid black",
                    padding: 10,
                  }}
                  autoComplete="new-password"
                  onChange={handlePasswordChange1}
                />
                <br />
                <div style={{}} id="recaptcha-container"></div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    width: "70%",
                    marginTop: "10px",
                  }}
                >
                  <div className="text-center">
                    <button
                      className="my-4 mx-auto btncreate"
                      onClick={(e) => {
                        checkemailphone(e);
                        // handleSignup(e);
                      }}
                    >
                      Create
                    </button>
                  </div>
                </div>
                <div className="py-4" style={{ color: "#bac1cb" }}>
                  Already have an account?
                  <span
                    className="ps-2"
                    style={{ color: "#ee5e8f", cursor: "pointer" }}
                    onClick={() => navigate("/patient/loginform")}
                  >
                    Login
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <AlertShow
        alertShowHide={showAlert}
        alertClose={handleCloseAlert}
        setalertMsg={alertMsg}
        severity={severity}
      />
    </div>
  );
}

export default Signup;
